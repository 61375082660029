import { Button as DesignSystemButton, ButtonProps } from '@loveholidays/design-system';
import React, { forwardRef } from 'react';

import { useFeatureFlag } from '@Core/octopus/useFeatureFlag';
import { BaseColors } from '@UX/themes/types';

export const Button = forwardRef<any, ButtonProps>((props: ButtonProps, ref) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const isYellowButton = props.variant === 'Primary' && useFeatureFlag('YellowButton');
  // Use `PrimaryLegacy` (green) variant for Primary buttons when `YellowButton` is off
  const variant = props.variant === 'Primary' && !isYellowButton ? 'PrimaryLegacy' : props.variant;

  return (
    <DesignSystemButton
      ref={ref}
      {...props}
      variant={variant}
      sx={
        isYellowButton
          ? {
              '&:hover,&:visited:hover': {
                backgroundColor: '#F8DA18' as unknown as BaseColors,
                color: 'textDefault',
              },
              '&,&:visited': {
                backgroundColor: '#FFBD2B' as unknown as BaseColors,
                color: 'textDefault',
              },
              ...(!props.disabled && {
                '&:active': {
                  backgroundColor: '#F8DA18' as unknown as BaseColors,
                },
              }),
              ...(props.disabled && {
                backgroundColor: '#ffe08c' as unknown as BaseColors,
                '&:hover': {
                  backgroundColor: '#ffe08c' as unknown as BaseColors,
                },
              }),
            }
          : {}
      }
    />
  );
});
